function carousel() {
    $(".logo-carousel").slick({
        dots: !1,
        infinite: !0,
        speed: 300,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: !0,
        pauseOnHover: !1,
        accessibility: !0,
        rows: 1,
        responsive: [{
            breakpoint: 1400,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: !0,
                dots: !1
            }
        }, {
            breakpoint: 900,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: !0,
                dots: !1
            }
        }, {
            breakpoint: 700,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }, {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    })
}

export {carousel}

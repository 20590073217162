window.$ = window.jQuery = require('jquery');
window.Cookies = require('js-cookie');
// window.swal = require('sweetalert2');

require('slick-carousel');
import {menu} from "./module/menu";
import {carousel} from "./module/carousel";
import {slider} from "./module/slider";
import {counter} from "./module/counter";
import {portfolio} from "./module/portfolio";
import {parallax} from "./module/parallax";
// import {projectFormController} from "./module/project-form-controller";
import {cookie} from "./module/cookies";


menu();
slider();
cookie();
carousel();

// let openModal = document.querySelectorAll('.js-openModal');
//
// for (let modal of openModal) {
//     modal.addEventListener('click', () => {
//         let topic = modal.getAttribute('data-topic');
//
//         projectFormController.init(topic);
//         document.querySelector('input[name=topic]').value = topic;
//     })
// }
//
//
// document.querySelector('button#openModal-mobile').addEventListener('click', () => {
//     projectFormController.init();
// });
//
// document.querySelector('.project-modal__close').addEventListener('click', () => {
//     projectFormController.exit();
// });


let elementsObj = [
    {
        id: 'value1',
        start: 0,
        end: 1243,
        duration: 3000,
        played: false
    },
    {
        id: 'value2',
        start: 0,
        end: 530,
        duration: 4000,
        played: false
    },
    {
        id: 'value3',
        start: 0,
        end: 134,
        duration: 5000,
        played: false
    },
    {
        id: 'value4',
        start: 0,
        end: 841,
        duration: 6000,
        played: false
    }
];

// $(document).on('scroll', () => {
//     let scrollPosition, elementOffset;
//
//     scrollPosition = window.scrollY + window.innerHeight;
//     elementOffset = $('.dynamic-number__section')[0].offsetTop;
//
//     if (scrollPosition > elementOffset) {
//
//         elementsObj.forEach((element, i) => {
//
//             if (!element.played) {
//                 counter(element.id, element.start, element.end, element.duration);
//                 elementsObj[i].played = true;
//             }
//         })
//     }
// });

//
// var lazyLoadInstance = new LazyLoad({
//     // Your custom settings go here
// });






function slider() {

    require('slick-carousel');

    changeSlideBoxText();
    sliderCarousel();
    logoCarousel();

    $(document).ready(function(){
        $('.js-advertisingSystem-slick').slick();
    });
}

function sliderCarousel() {
    $('.js-slick__item').slick({
        autoplay: true,
        arrows: true,
        autoplaySpeed: 3000,
        dots: true,
        prevArrow: '<span class="slick-prev" aria-label="Previous"></span>',
        nextArrow: '<span class="slick-next" aria-label="Next"></span>',
        customPaging: function(slider, i) {
            // this example would render "tabs" with titles
            return '<span class="dots"></span>';
        },

    });
}

function logoCarousel() {
    // carousel

    $('.carousel').slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: false,
        accessibility: true,
        rows: 2,
        responsive: [
            {
                breakpoint: 770,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });
}

function changeSlideBoxText() {
    let index = ["one", "two"];
    let text = {
        one:  {
            green: {
                title: "branding",
                one: 'Kreowanie',
                two: 'Wizerunku',
                three: 'Firm',
            },
            grey: {
                one: 'Kampanie',
                two: 'reklamowe',
            },
            white: {
                title: "www",
                one: 'Portale',
                two: 'Sklepy',
                three: 'Strony',
            }
        },
        two:  {
            green:  {
                title: "branding1",
                one: 'Kreowanie1',
                two: 'Wizerunku1',
                three: 'Firm1',
            },
            grey: {
                one: 'Kampanie1',
                two: 'reklamowe1',
            },
            white: {
                title: "www1",
                one: 'Portale1',
                two: 'Sklepy1',
                three: 'Strony1',
            }
        },
    };
    let currentIndex = 0;
    let curSlide = 0;

    $(".js-slick__item").on("afterChange", function (event, slick, currentSlide, nextSlide){

        if (curSlide !== currentSlide)  {
            curSlide = currentSlide;

            $(".js-first-block h4").text(text[index[curSlide]].green.title);
            $(".js-first-block h3:nth-child(1)").text(text[index[curSlide]].green.one);
            $(".js-first-block h3:nth-child(2)").text(text[index[curSlide]].green.two);
            $(".js-first-block h3:nth-child(3)").text(text[index[curSlide]].green.three);

            $(".js-second-block h3:nth-child(1)").text(text[index[curSlide]].grey.one);
            $(".js-second-block h3:nth-child(2)").text(text[index[curSlide]].grey.two);

            $(".js-third-block h4").text(text[index[curSlide]].white.title);
            $(".js-third-block h3:nth-child(1)").text(text[index[curSlide]].white.one);
            $(".js-third-block h3:nth-child(2)").text(text[index[curSlide]].white.two);
            $(".js-third-block h3:nth-child(3)").text(text[index[curSlide]].white.three);


        }

    });
}




export { slider }

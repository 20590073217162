function menu() {

    let hamburgerIcon = document.querySelector('.hamburger');
    let menuList = document.querySelector('.menu');
    let body = document.querySelector('body');
    let html = document.querySelector('html');
    let btnMenuOnTop = document.querySelector('.button--visible-in-mobile');
    let btnMenuOnBottom = document.querySelector('.button--hidden-in-mobile');
    let arrowBottom = document.querySelector('.arrow--small');

// drop-down menu list in mobile version when click hamburger icon

    hamburgerIcon.addEventListener('click', function () {
        menuList.classList.toggle('active');
        body.classList.toggle('unscrollable');
        html.classList.toggle('unscrollable');
        hamburgerIcon.classList.toggle('active');
        btnMenuOnTop.classList.toggle('active');
        btnMenuOnBottom.classList.toggle('active');
    });

    let linkSubmenu = document.querySelector('.link--with-arrow');
    let submenu = document.querySelector('.submenu');
    let arrowBottomMenuMobile = document.querySelector('.arrow--small');
    let containerItem = document.querySelector('.container__item');
    let linkAnchor = document.querySelector('.link--anchor');
    $(window).scroll(function () {
        $(window).scrollTop() > 50 ? ($(".navbar").addClass("short"),
            $(".button--visible-in-mobile").addClass("short"),
            $(".menu").addClass("short")) : ($(".navbar").removeClass("short"),
            $(".button--visible-in-mobile").removeClass("short"),
            $(".menu").removeClass("short"))
    }),
        $(window).width() < 1363 ? $(".link").addClass("js-link-click") : ($(".link").addClass("js-link"),
            $(".submenu").mouseleave(function () {
                $(this).removeClass("active")
            })),
        $(window).on("resize", function () {
            $(this).width() < 1363 ? ($(".link").removeClass("js-link"),
                $(".link").addClass("js-link-click")) : ($(".link").removeClass("js-link-click"),
                $(".link").addClass("js-link"))
        }),
        $(".js-link").mouseenter(function () {
            $(".container__item").find(".submenu").removeClass("active");
            $(this).closest(".container__item").find(".submenu").addClass("active");
        }),
        $(".js-link-click").click(function () {
            $(".container__item").find(".submenu").removeClass("active");
            $(this).closest(".container__item").find(".submenu").addClass("active");
            console.log($(this).closest(".container__item").find(".submenu"))
        }),

//  animation for a small arrow at the link

        linkSubmenu.addEventListener('click', () => {
            arrowBottomMenuMobile.classList.toggle('active');
            // submenu.classList.toggle('active');
        });

// folding up menu list in mobile version when click this link
    if (linkAnchor != null) {
        linkAnchor.addEventListener('click', () => {
            menuList.classList.remove('active');
            hamburgerIcon.classList.remove('active');
            body.classList.remove('unscrollable');
            html.classList.remove('unscrollable');
        })

        // smooth scroll to the section about

        $('a[href*=\\#]').on('click', function (event) {
            console.log($(this.hash).offset());
            $('html,body').animate({
                scrollTop: $(this.hash).offset().top
            }, 1000);
        });
    }


// delay of transition for the hover of the small arrow at the link

    containerItem.addEventListener('mouseout', () => {
        addDelayArrow();
        setTimeout(function () {
            arrowBottom.style.transitionDelay = '0s';
        }, 500);
        console.log(arrowBottom);
    })

    function addDelayArrow() {
        arrowBottom.style.transitionDelay = '.3s';
    }

// smaller size of the menu list when scrolling


    $(window).scroll(function () {
        var top = $(window).scrollTop();

        if (top > 50) {
            $('.navbar').addClass('short');
            $('.button--visible-in-mobile').addClass('short');
            $('.menu').addClass('short');

        } else {
            $('.navbar').removeClass('short');
            $('.button--visible-in-mobile').removeClass('short');
            $('.menu').removeClass('short');
        }

    });


}

export {menu}

function cookie() {

    $('.js-cookie-close').click(function () {
        $('.cookie').hide();
        Cookies.set('policy', 1, { expires: 7 });
    });
}


export { cookie }
